export const nl2br = (str: string | undefined) => {
  if (typeof str === 'undefined' || str === null) {
    return undefined
  }

  return (str + '').replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    '$1' + '<br>' + '$2'
  )
}
